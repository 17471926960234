import { CommonModule } from '@angular/common';
import { TextInputComponent } from './text/text-input.component';
import { INPUT_TEMPLATE_SERVICE } from './input-template-service.token';
import { InputTemplateBaseService } from './input-template.service';
import { ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from '@tmhcc-ng-lib/framwork/form-inputs';
import { NumberInputComponent } from './number/number-input.component';
import { TextareaInputComponent } from './textarea/textarea.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { ReadyOnlyComponent } from './read-only/read-only.component';
import { RadioButtonComponent } from './radio-button/radio-button.component';
import { EmailInputComponent } from './email/email-input.component';
import { SwitchButtonComponent } from './switch-button/switch-button.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { InputControlComponent } from './input-generator/input-generator.component';
import { IINPUT_TEMPLATE_SERVICE } from './injection-tokens';
import { SelectListComponent } from './select-list/select-list.component';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { NgSelectModule } from '@ng-select/ng-select';
import { InputSearchComponent } from './input-search/input-search.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomValidationComponent } from './custom-input-validation/custom-validation.component';
import { InputRefDirective } from './custom-input-validation/custom-validation.directive';
import { CoreInputValidationService } from './custom-input-validation/custom-validation.service';
import { PipesModule } from '@tmhcc-ng-lib/framwork/pipes';
import { CustomLinkComponent } from './custom-link/custom-link.component';
import { SpacerControlComponent } from './spacer-control/spacer-control.component';
import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CustomLabelComponent } from "./label/custom-label.component";

const coreInputs = [
  InputControlComponent,
  TextInputComponent,
  NumberInputComponent,
  TextareaInputComponent,
  CheckboxComponent,
  ReadyOnlyComponent,
  RadioButtonComponent,
  EmailInputComponent,
  SwitchButtonComponent,
  DatePickerComponent,
  SelectListComponent,
  InputSearchComponent,
  CustomValidationComponent,
  InputRefDirective,
  CustomLinkComponent,
  SpacerControlComponent,
  CustomLabelComponent,
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputTextModule,
    SelectDropDownModule,
    NgbModule,
    PipesModule,
    BsDatepickerModule,
    NgSelectModule
  ],
  schemas: [NO_ERRORS_SCHEMA],
  declarations: [coreInputs],
  exports: [coreInputs],
  providers: [
    CoreInputValidationService,
    { provide: IINPUT_TEMPLATE_SERVICE, useClass: InputTemplateBaseService }
  ]
})
export class CoreInputsModule {}
