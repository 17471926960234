import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { BaseDataService } from './base-data-service';
import { AppConfigService } from '@tmhcc-ng-lib/global-services';
import { HttpHeaders } from "@angular/common/http";
import { map, catchError } from 'rxjs/operators';
import { throwError,of } from 'rxjs';
import {
  PciEndorsementsDataResponse,
  PciEndorsementsResponse,
  PciEndorsementData
} from "../models/classes/pci-endorsements-data.model";
import {  
  PciSaveEndorsementRequest
} from "libs/data-service/def/pci-endorsement.d";
@Injectable()
export class PciEndorsementsService extends BaseDataService {
  endorsementsDataResponseCache: PciEndorsementsDataResponse;
  constructor(
    protected http: HttpClient,
    protected configService: AppConfigService
  ) {
    super(http, configService);
  }

  getEndorsementBySubmissionId(submissionId:string)  : Observable<PciEndorsementsResponse>
  {   
      let baseURL = this.serviceBaseUrl;   
      let serviceApiKey = this.config.dataServiceApiKey;
      const headerDict = {
      'Content-Type': 'application/json',
      'x-api-key': serviceApiKey
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };         
      const url = `${baseURL}/api/v1.0/endorsements/${submissionId}`;
    return this.get<PciEndorsementsResponse>(url,requestOptions).pipe(
            map(data => this.mapToEndorsementGridRow(data)),
            catchError(this.handleError)
          );
  }
  mapToEndorsementGridRow(data: any): PciEndorsementsResponse {    
    let endorsementsResponse = new PciEndorsementsResponse();
    if (!data || !data.endorsementsData) return;
    if (data.endorsementsData) {
      let gridRows: PciEndorsementData[] = [];
      for (let index = 0; index < data.endorsementsData.length; index++) {
        const element = data.endorsementsData[index];
        let row = new PciEndorsementData();        
        row.endorsementName = element.endorsementName;        
        row.id = element.id;
        row.formCode = element.formCode;
        row.formId = element.formId;
        row.version = element.version;       
        gridRows.push(row);
      }
      endorsementsResponse.endorsementsData = gridRows;
      endorsementsResponse.riskId = data.riskId;
      endorsementsResponse.endorsements = data.endorsements;
    }    
    return endorsementsResponse;
  }
  saveEndorsements(payload:any): Observable<string>  {
    let baseURL = this.serviceBaseUrl;   
    let serviceApiKey = this.config.dataServiceApiKey;      
    const url = `${baseURL}/api/v1.0/endorsements`;   
    return this.postv1<PciSaveEndorsementRequest>(url, { body: payload },serviceApiKey).pipe(
      map(data => this.mapSaveEndorsement(data)) ,
      catchError(this.handleError)     
    );
  }
  mapSaveEndorsement(data: any) {   
    return data;
  }
  // deleteSubjectivity(submissionid:any,subjectivityid:any) : Observable<any>
  // {   
  //   let status: any;
  //   let errorMessage: any;
  //   let baseURL = this.serviceBaseUrl;   
  //     let serviceApiKey = this.config.dataServiceApiKey;
  //     const headerDict = {
  //     'Content-Type': 'application/json',
  //     'x-api-key': serviceApiKey
  //   }
  //   const requestOptions = {                                                                                                                                                                                 
  //     headers: new HttpHeaders(headerDict), 
  //   };         
  //   const url = `${baseURL}/api/v1.0/subjectivities/${submissionid}/${subjectivityid}`;
  //   return this.http.delete<any>(url,requestOptions).pipe(
  //     map(response => response),
  //     catchError(this.handleError)
  //   );    
  // }
  // saveSubjectivity(payload:any): Observable<string>  {
  //   let baseURL = this.serviceBaseUrl;   
  //   let serviceApiKey = this.config.dataServiceApiKey;      
  //   const url = `${baseURL}/api/v1.0/subjectivities`;   
  //   return this.postv1<PciSaveSubjectiviyRequest>(url, { body: payload },serviceApiKey).pipe(
  //     map(data => this.mapSaveSubjectivity(data)) ,
  //     catchError(this.handleError)     
  //   );
  // }
  // updateSubjectivity(payload:any): Observable<string>  {
  //   let baseURL = this.serviceBaseUrl;   
  //   let serviceApiKey = this.config.dataServiceApiKey;      
  //   const url = `${baseURL}/api/v1.0/subjectivities`;   
  //   return this.putv1<PciSubjectivityUpdateRequest>(url, { body: payload },serviceApiKey).pipe(
  //     map(data => this.mapUpdateSubjectivity(data)) ,
  //     catchError(this.handleError)     
  //   );
  // }
  // mapUpdateSubjectivity(data: any) {   
  //   return data;
  // }
  // mapSaveSubjectivity(data: any) {   
  //   return data;
  // }
  protected handleError(err) {
    return throwError(err);
  }
}
