import { Routes, Router } from '@angular/router';

import { OktaAuthGuard } from '@tmhcc-ng-lib/framwork/okta-login';

export function onAuthRequired(oktaAuth, injector) {
  const router = injector.get(Router);
  router.navigate(['/login']);
}

const routes: Routes = [
  {
    path: 'dashboard',
    loadChildren: () =>
      import('@tmhcc-product-contamination/product-contamination/dashboard').then(
        m => m.DashboardModule
      ),
    canActivate: [OktaAuthGuard],
    data: {
      onAuthRequired
    }
  },
  {
    path: 'setup',
    loadChildren: () =>
      import('./quote/account-setup/account-setup.module').then(
        m => m.AccountSetupModule
      ),
    canActivate: [OktaAuthGuard],
    data: {
      onAuthRequired
    }
  },
  {
    path: 'location-details',
    loadChildren: () =>
      import('./quote/location-details/location-details.module').then(
        m => m.LocationDetailsModule
      ),
    canActivate: [OktaAuthGuard],
    data: {
      onAuthRequired
    }
  },
  {
    path: 'quote-details',
    loadChildren: () =>
      import('./quote/quote-details/quote-details.module').then(
        m => m.QuoteDetailsModule
      ),
    canActivate: [OktaAuthGuard],
    data: {
      onAuthRequired
    }
  },
  {
    path: 'risk-codes/food-and-beverage',
    loadChildren: () =>
      import('./quote/rick-codes/risk-codes/risk-codes.module').then(
        m => m.RiskCodesModule
      ),
    canActivate: [OktaAuthGuard],
    data: {
      onAuthRequired
    }
  },
  {
    path: 'risk-codes/non-food',
    loadChildren: () =>
      import('./quote/rick-codes/risk-codes/risk-codes.module').then(
        m => m.RiskCodesModule
      ),
    canActivate: [OktaAuthGuard],
    data: {
      onAuthRequired
    }
  },
  {
    path: 'step3/subjectivity',
    loadChildren: () =>
      import('./quote/pci-subjectivities/pci-subjectivity.module').then(
        m => m.PciSubjectivitiesModule
      ),
    canActivate: [OktaAuthGuard],
    data: {
      onAuthRequired
    }
  },
  {
    path: 'coverages/excess',
    loadChildren: () =>
      import('./quote/coverages/coverages/coverages-excess.module').then(
        m => m.CoveragesExcessModule
      ),
    canActivate: [OktaAuthGuard],
    data: {
      onAuthRequired
    }
  },
  // {
  //   path: 'coverages/excess',
  //   loadChildren: () =>
  //     import('./quote/coverages/coverages/coverages.module').then(
  //       m => m.CoveragesModule
  //     ),
  //   canActivate: [OktaAuthGuard],
  //   data: {
  //     onAuthRequired
  //   }
  // },
  {
    // path: 'step3/coverages-primary-food',
    path: 'coverages/primary',
    loadChildren: () =>
      import('./quote/coverages-primary-food/coverages-pf.module').then(
        m => m.PfCoveragesModule
      ),
    canActivate: [OktaAuthGuard],
    data: {
      onAuthRequired
    }
  },
  {
    // path: 'step3/coverages-primary-food',
    path: 'coverages/primary-non-food',
    loadChildren: () =>
      import('./quote/coverages-primary-non-food/coverages-pnf.module').then(
        m => m.PnfCoveragesModule
      ),
    canActivate: [OktaAuthGuard],
    data: {
      onAuthRequired
    }
  },
  {
    path: 'step3/endorsements',
    loadChildren: () =>
      import('./quote/pci-endorsements/pci-endorsements.module').then(
        m => m.PciEndorsementsModule
      ),
    canActivate: [OktaAuthGuard],
    data: {
      onAuthRequired
    }
  },
  {
    path: 'installments',
    loadChildren: () =>
      import('./quote/installments/installments/installments.module').then(
        m => m.InstallmentsModule
      ),
    canActivate: [OktaAuthGuard],
    data: {
      onAuthRequired
    }
  },
  {
    path: 'approval/primary',
    loadChildren: () =>
      import('./quote/pci-approval/primary-food-nonfood/primary.module')
        .then(m => m.ApprovalPrimaryFoodModule),
    canActivate: [OktaAuthGuard],
    data: {
      onAuthRequired
    }
  },
  {
    path: 'approval/excess-food',
    loadChildren: () =>
      import('./quote/pci-approval/excess-food-nonfood/excess-food/excess-food.module')
        .then(m => m.ApprovalExcessFoodModule),
    canActivate: [OktaAuthGuard],
    data: {
      onAuthRequired
    }
  },
  {
    path: 'approval/excess-nonfood',
    loadChildren: () =>
      import('./quote/pci-approval/excess-food-nonfood/excess-nonfood/excess-nonfood.module')
        .then(m => m.ApprovalExcessNonFoodModule),
    canActivate: [OktaAuthGuard],
    data: {
      onAuthRequired
    }
  },
  {
    path: 'quotes',
    loadChildren: () =>
      import('./quote/quote-steps/quote-steps.module').then(
        m => m.QuoteStepsModule
      ),
    canActivate: [OktaAuthGuard],
    data: {
      onAuthRequired
    }
  },
  {
    path: 'bind',
    loadChildren: () =>
      import('./quote/bind/bind.module').then(m => m.BindModule),
    canActivate: [OktaAuthGuard],
    data: {
      onAuthRequired
    }
  },
  {
    path: 'approval',
    loadChildren: () =>
      import('./quote/policy-details/policy-details.module').then(
        m => m.PolicyDetailsModule
      ),
    canActivate: [OktaAuthGuard],
    data: {
      onAuthRequired
    }
  },
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: '**', redirectTo: 'dashboard' }
];

export const PCIRoutes = routes;