export class PciEndorsementsDataResponse {
    constructor(
       // public endorsements: PciEndorsements[]
    ) { }
}
export class PciEndorsements{
  id?:string;
  type?: string;
  endorsementName: string;
}    


export class endorsementsData{
  endorsementsData: PciEndorsementData[];
  }

export class PciEndorsementsResponse {
  riskId: string;
  endorsements: endorsementResponse[];
  endorsementsData: PciEndorsementData[];
} 
export class endorsementResponse{
  id: string;
  formId: number;
  formCode: string;
  endorsementName: string;
  version: string;
  limit: number;
  selfInsuredRetention: number;
}
export class PciEndorsementData {    
  id?: string;
  formId?: string;
  formCode: string;
  endorsementName: string
  version: string;
    constructor() {}
}
export class endorsementGridRow{
  id: string;
  formId: number;
  formCode: string;
  endorsementName: string;
  version: string;
  limit: number;
  selfInsuredRetention: number;
}

  