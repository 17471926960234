import { Provider } from '@angular/core';
import { DashboardService } from './dashboard.service';
import { ClaimSearchService } from './cliam-search.service';
import { ClaimPolicySearchService } from './claim-policy-search.service';
import { ClaimsMasterData } from './claims-master.service';
import { ClaimGetPolicyService } from './claim-get-policy.service';
import { UpdateClaimService } from './update-claim.service';
import { BaseSiteCoreAPIMapper } from './sitecore-data-mapper/base-sitecore-api-mapper.service';
import { FormSitecoreMapperService } from './sitecore-data-mapper/form-site-core-mapper.service';
import { GridSitecoreMapperService } from './sitecore-data-mapper/grid-site-core-mapper.service';
import { GetCorePageDataService } from './sitecore-data-mapper/get-core-page-data.service';
import { ClaimMasterDataService } from './claim-master.service';
import { ClaimMasterPolicyDataService } from './claim-master-policy.service';
import { BaseResolverService } from './resolver/base-data-resolver';
import { FormsService } from './forms.service';
//import { expr } from 'jquery';
import { IssueNewBusinessService } from './issue-new-business.service';
import { SubjectivityService } from './subjectivity.service';
import { UCMDataService } from './ucm-data.service';
import { UCMFormSitecoreMapperService } from './sitecore-data-mapper/ucm-form-site-core-mapper.service';
import { CoverageLimitFormBuilderService } from './coverage-limit-formbuilder.service';
import { PciInsuredSearchService } from './pci-insured-search.service';
import { LocationsDetailsFormBuilderService } from './locations-details-formbuilder.service';
import { RriCoverageStatusService } from './rri-coverage-status.service';
import { BindProcessService } from './bind-process.service';
import { FranchiseFormBuilderService } from './franchise-formbuilder.service';
import { CoverageMappingService } from './coverage-mapping.service';
import { CancellationAdjustmentService } from './cancellation-adjustment.service';
import { PciSubjectivityService } from './pci-subjectivity.service'
import { PciCoveragesService } from './pci-coverages.service';
import { PciEndorsementsService } from './pci-endorsement.service';
import { PciApprovalService } from './pci-approval.service';
// services
export * from './dashboard.service';
export * from './cliam-search.service';
export * from './claim-policy-search.service';
export * from './claims-master.service';
export * from './claim-get-policy.service';
export * from './update-claim.service';
export * from './forms.service';
export * from './issue-new-business.service';
export * from './quote-step.service';
export * from './ucm-data.service';
export * from './rri-coverage-status.service';
// export { claimMasterResolver } from './claim-master-resolver.service';
export * from './base-data-service';
export * from './subjectivity.service';
export * from './sitecore-data-mapper/base-sitecore-api-mapper.service';
export * from './sitecore-data-mapper/get-core-page-data.service';
export * from './base-data-service';
export * from './sub-company.service';

export * from './resolver/base-data-resolver';
// definitions
export {
  DashboardRequest,
  DashboardResponse,
  PendingClaimResponse,
  PendingClaimPaymentsResponse,
  PendingClaimReservesResponse,
  PendingClaimPayment,
  PendingClaimReserve,
  ApprovedRequest,
  DeclineRequest,
  ApprovedRequestPost,
  reservesClaimId,
  ApprovedRequestPayment,
  payments,
  ClaimDashBoardMetrics,  
}
  from './def/get-dashboard';
export {
  ClaimSearchRequest,
  AddEventRequest,
  AddEventResponse,
  ClaimSearchResponse,
  ClaimSearchResult,
  ClaimEventSearch,
  ClaimEventSearchResponse,
  EventSearchResult,
  ClaimEventMasterData,
}
  from './def/claim-search';
// export { ClaimsMasterRequest, ClaimsMasterResponse } from './def/claim-master';
export {
  ClaimGetPolicyRequest,
  ClaimGetPolicyResponse,
  ActivityHistory,
  CreateActivityLogItemNew,
  PolicyContact,
  PolicyMovement,
  PolicyName,
  UWQuote,
  Lob,
  Reinsurance,
  Risk,
  Coverage,
  RiskCustomExcess,
  UpdateUserConfigurableDataElement,
  UpdateUserConfigurableDataElementElementUpdateRequest,
  UWLocation,
  ProducerCommission,
  TaxesAndFees,
  AdditionalTaxesAndFeesResponseItem,
  TaxDetail,
  UWQuotaShare,
  PolicyDataElements,
  PolicyDataElementsElementUpdateRequest
}
  from './def/claim-get-policy';
export * from './def/sitecore-data-def';
export * from './def/update-claim';
export * from './def/save-claim';
export * from './def/claim-subcompany';
export {
  GetDashboardResponse,QuoteDetails
}
  from './def/dashboard';
export { 
  IssueNewBusinessRequest,
  CreateActivityLogItemForNB,
  IssueNewBusinessResponse  
} from './def/issue-new-business';
export {
  SaveEventRequest,
  policyDataElements,
  UpdateEventDetails,
  UpdateUserConfigurableDataElements,
  RiskDataElements,
  ElementUpdateRequests,
  SaveEventResponse  
}
  from './def/events';
export {
  EndorsmentFormsRequest,
  EndorsementFormsResponse,
  AvailableFormsResponse,
  ApproveQuoteRequest,
  EndorsementForms,
  AvailableForms,
  AddOptionalEndorsementFormsECO,
  CreateActivityLogItems,
  PolicyDataElement,
  ElementUpdateRequestObj,
  OptionalEndorsementForms,
  CustomFields,
  AddOptionalEndorsementResponse
}
  from './def/endorsment-forms';
export {
  SaveSubjectiviyRequest,
  GetPolicySubjectivitiesRequest,
  SaveMiscellaneousChangesRequest,
  SaveMiscellaneousChangesResponse,
  PolicySubjectivityResponse,
  SubjectivityRequest,
  Subjectivity,
  ConfigSubjectivities,  
}
 from './def/subjectivity';
export * from './def/coverage-limit-data-elements';
export * from './def/coverage-policies';
export {
  PolicyTxnRequest,
  PolicyTxnResponse,
  AdditionalSummaries,
  CreateActivityLogItemObj,
  UWQuotes,
  UWQuotaShares,
  ProducerCommissions,
  LOBs,
  LOBRisks,  
  PolicyContacts,
  QuoteOptions,
  RewritePolicy,
  PolicySubmissionTxnResponse,
  ManualTaxAndFeeRequest,
  ManualTaxAndFeeResponse
}
  from './def/quote-policy-step';
export {QouteProductType} from './def/rri-risk-product';
export * from './locations-details-formbuilder.service';
export const DataServiceProviders: Provider[] = [
  DashboardService,
  ClaimSearchService,
  ClaimPolicySearchService,
  ClaimsMasterData,
  ClaimGetPolicyService,
  UpdateClaimService,
  BaseSiteCoreAPIMapper,
  FormSitecoreMapperService,
  GridSitecoreMapperService,
  UCMFormSitecoreMapperService,
  GetCorePageDataService,
  ClaimsMasterData,
  BaseResolverService,
  ClaimMasterDataService,
  ClaimMasterPolicyDataService,
  FormsService,
  IssueNewBusinessService,
  SubjectivityService,
  UCMDataService,
  PciInsuredSearchService,
  CoverageLimitFormBuilderService,
  LocationsDetailsFormBuilderService,
  RriCoverageStatusService,
  BindProcessService,
  FranchiseFormBuilderService,
  CoverageMappingService,
  CancellationAdjustmentService,
  PciSubjectivityService,
  PciCoveragesService,
  PciEndorsementsService,
  PciApprovalService
];
export * from './coverage-limit-formbuilder.service';
export * from './franchise-formbuilder.service';
export * from './coverage-mapping.service';
//export * from './security-user-group.service';
export * from './claim-master.service';
export * from './sitecore-data-mapper/form-site-core-mapper.service';
export * from './sitecore-data-mapper/grid-site-core-mapper.service';
export * from './pci-insured-search.service';
export * from './pci-subjectivity.service';
export * from './pci-coverages.service';
export * from './pci-endorsement.service';
export * from './pci-approval.service';