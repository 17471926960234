import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { BaseDataService } from './base-data-service';
import { AppConfigService } from '@tmhcc-ng-lib/global-services';
import { HttpHeaders } from "@angular/common/http";
import { map, catchError } from 'rxjs/operators';
import { throwError, of } from 'rxjs';
import { coveragesPfResponse } from "../models/classes/pci-coverages-pf.model";
import { coveragePrimeryFoodRequest } from 'libs/data-service/def/coverage-pf-request.d';
import { coveragePrimeryNonFoodRequest } from 'libs/data-service/def/coverage-pnf-request.d';
import { coveragesPnfResponse } from "../models/classes/pci-coverages-pnf.model";
@Injectable()
export class PciCoveragesService extends BaseDataService {    
    constructor(
        protected http: HttpClient,
        protected configService: AppConfigService
    ) {
        super(http, configService);
    }

  saveCoveragesPrimaryFood(payload:any): Observable<string>  {
      let baseURL = this.serviceBaseUrl;   
      let serviceApiKey = this.config.dataServiceApiKey;      
      const url = `${baseURL}/api/v1.0/coverages/primary/food`;   
      return this.postv1<coveragePrimeryFoodRequest>(url, { body: payload },serviceApiKey).pipe(
        map(data => this.mapSaveCoveragesPrimaryFood(data)) ,
        catchError(this.handleError)     
      );
  }
  mapSaveCoveragesPrimaryFood(data: any) {
    return data;
  }
  saveCoveragesPrimaryNonFood(payload:any): Observable<string>  {
    let baseURL = this.serviceBaseUrl;   
    let serviceApiKey = this.config.dataServiceApiKey;      
    const url = `${baseURL}/api/v1.0/coverages/primary/nonfood`;   
    return this.postv1<coveragePrimeryNonFoodRequest>(url, { body: payload },serviceApiKey).pipe(
      map(data => this.mapSaveCoveragesPrimaryNonFood(data)) ,
      catchError(this.handleError)     
    );
}
mapSaveCoveragesPrimaryNonFood(data: any) {
  return data;
}
  protected handleError(err) {
        return throwError(err);
  }
  getCoveragesPrimaryFood(submissionId:any) {
    let baseURL = this.serviceBaseUrl;   
    let serviceApiKey = this.config.dataServiceApiKey;
    const headerDict = {
    'Content-Type': 'application/json',
    'x-api-key': serviceApiKey
  }
  const requestOptions = {                                                                                                                                                                                 
    headers: new HttpHeaders(headerDict), 
  };         
    const url = `${baseURL}/api/v1.0/coverages/primary/food/${submissionId}`;
  return this.get<coveragesPfResponse>(url,requestOptions).pipe(
          map(data => this.mapToCoveragesPfResponse(data)),
          catchError(this.handleError)
        );
  }
  mapToCoveragesPfResponse(data: any) {
    let covData = new coveragesPfResponse(data);
    return covData
  }
  getCoveragesPrimaryNonFood(submissionId:any) {
    let baseURL = this.serviceBaseUrl;   
    let serviceApiKey = this.config.dataServiceApiKey;
    const headerDict = {
    'Content-Type': 'application/json',
    'x-api-key': serviceApiKey
  }
  const requestOptions = {                                                                                                                                                                                 
    headers: new HttpHeaders(headerDict), 
  };         
  const url = `${baseURL}/api/v1.0/coverages/primary/nonfood/${submissionId}`;
  return this.get<coveragesPnfResponse>(url,requestOptions).pipe(
          map(data => this.mapToCoveragesPnfResponse(data)),
          catchError(this.handleError)
        );
  }
  mapToCoveragesPnfResponse(data: any) {
    let covData = new coveragesPnfResponse(data);
    return covData
  }
    
}
