import { Component, forwardRef, EventEmitter, Output } from "@angular/core";
import { InputBase } from "../input-base";
import { NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "hcc-custom-label",
  templateUrl: "./custom-label.component.html",
  styleUrls: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomLabelComponent),
      multi: true
    }
  ]
})
export class CustomLabelComponent extends InputBase<string> {
  @Output() blurEvent = new EventEmitter();
  constructor() {
    super();
  } 
}
