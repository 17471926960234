export enum AllowedRoute {
  Dashboard = '/dashboard',

  UCMQuoteDetails = '/ucm-forms/quote-details',
  UCMQuoteInfo = '/ucm-forms/quoteInfo',
  UCMAddInsured = '/ucm-forms/add-insured',
  UCMAccountDeatils = '/ucm-forms/account-details',

  AccountInfo = '/setup',
  InsuredSearch = '/setup/insured-search',

  AccountDetailsForProgressBar = '/setup/account-details',

  RiskCodesFB = '/risk-codes/food-and-beverage',
  RiskCodesNF = '/risk-codes/non-food',

  Subjectivity = '/step3/subjectivity',

  CoverageExcess = '/coverages/excess',
  CoveragePrimary = '/coverages/primary',
  CoveragePrimaryNF = '/coverages/primary-non-food',

  Instalments = '/installments',

  ApprovalPrimary = '/approval/primary',
  ApprovalExcessFood = '/approval/excess-food',

  // Quote = '/quoteInfo',

  QuoteSubjectivities = '/quotes/subjectivities',
  QuoteCoverageLimit = '/quotes/coverage-limit',

  Location = '/location-details',
  LocationDetils = '/location-details/locations-details-franchise-information',
  FranchiseDetils = '/location-details/locations-details-franchise-details',

  Bind = '/bind',
  BindForm = '/bind/forms',
  BindSubjectivities = '/bind/subjectivities',
  BindQuoteInfoSummary = '/bind/quoteInfo-summary',

  Document = '/document',
  Forms = '/policy-forms',
  Summary = '/bind-summary',

  Approval = '/approval',
  AccountDetails = '/account-details'
}
