import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfigService } from '@tmhcc-ng-lib/global-services';
import { AppConfig } from '@tmhcc-ng-lib/models';
import { Observable } from 'rxjs/internal/Observable';
import { HttpOptions } from '@tmhcc-ng-lib/framwork/http';
// import { HttpOptions as AgHttpOptins } from '@tmhcc-ng-lib/framwork/http';
// import { HttpOptions } from './def/data-service';

export abstract class BaseDataService {
  protected config: AppConfig;
  protected serviceBaseUrl: string;
  protected token: any;

  constructor(
    protected http: HttpClient,
    protected configService: AppConfigService
  ) {    
    this.config = configService.config;
    this.serviceBaseUrl = this.config.dataServiceBaseUrl;
    this.token = JSON.parse(localStorage.getItem('okta-token-storage'));
  }

  protected get<T>(
    url: string,
    options?: HttpOptions,
    appendUserIdInHeader = false
  ): Observable<T> {
    if (!options) options = {};
    if (appendUserIdInHeader) {
      options.headers = this.appendUserMailHeader(options.headers);
    } else {
      options.headers = this.appendDefaultHeaders(options.headers);
    }
    return this.http.get<T>(url, options);
  }

  protected post<T>(
    url: string,
    options?: HttpOptions,
    appendUserIdInHeader = false
  ): Observable<T> {
    if (!options) options = {};
    if (appendUserIdInHeader) {
      options.headers = this.appendUserMailHeader(options.headers);
    } else {
      options.headers = this.appendDefaultHeaders(options.headers);
    }

    return this.http.post<T>(url, options.body, {
      params: options.params,
      headers: options.headers
    });
  }
  protected postV2<T>(
    url: string,
    options?: HttpOptions,
    appendUserIdInHeader = false
  ): Promise<T> {
    if (!options) options = {};
    if (appendUserIdInHeader) {
      options.headers = this.appendUserMailHeader(options.headers);
    } else {
      options.headers = this.appendDefaultHeaders(options.headers);
    }

    return this.http
      .post<T>(url, options.body, {
        params: options.params,
        headers: options.headers
      })
      .toPromise();
  }

  protected put<T>(
    url: string,
    options?: HttpOptions,
    appendUserIdInHeader = false
  ): Observable<T> {
    if (!options) options = {};
    if (appendUserIdInHeader) {
      options.headers = this.appendUserMailHeader(options.headers);
    } else {
      options.headers = this.appendDefaultHeaders(options.headers);
    }

    return this.http.put<T>(url, options.body, {
      params: options.params,
      headers: options.headers
    });
  }

  protected delete<T>(
    url: string,
    options?: HttpOptions,
    appendUserIdInHeader = false
  ): Observable<T> {
    if (!options) options = {};
    if (appendUserIdInHeader) {
      options.headers = this.appendUserMailHeader(options.headers);
    } else {
      options.headers = this.appendDefaultHeaders(options.headers);
    }
    return this.http.delete<T>(url, options);
  }

  private appendDefaultHeaders(headers?: HttpHeaders): HttpHeaders {   
    if (!headers) {
      headers = new HttpHeaders();
    }  
    return headers
      .set('x-api-key', this.config.dataServiceApiKey)
      .set('content-type', 'application/json')
      .set('Authorization', 'Bearer ' + this.token.accessToken.accessToken);      
  }

  private appendUserMailHeader(headers?: HttpHeaders): HttpHeaders {    
    if (!headers) {
      headers = new HttpHeaders();
    }     
   
    return headers
      .set('x-api-key', this.config.dataServiceApiKey)
      .set('content-type', 'application/json')
      .set(
        'UserEmail',
        sessionStorage.getItem('UserId') ? sessionStorage.getItem('UserId') : '')
      .set('Authorization', 'Bearer ' + this.token.accessToken.accessToken);
  }

  protected postv1<T>(url: string, options?: HttpOptions, serviceapikey?: string): Observable<T> {
    if (!options) options = {};
    options.headers = this.appendDwldHeaders(options.headers,serviceapikey);
    return this.http.post<T>(url, options.body, {
      params: options.params,
      headers: options.headers
    });
  }
  protected putv1<T>(url: string, options?: HttpOptions, serviceapikey?: string): Observable<T> {
    if (!options) options = {};
    options.headers = this.appendDwldHeaders(options.headers,serviceapikey);
    return this.http.put<T>(url, options.body, {
      params: options.params,
      headers: options.headers
    });
  }
  private appendDwldHeaders(headers?: HttpHeaders, serviceapikey?: string): HttpHeaders {    
    if (!headers) {
      headers = new HttpHeaders();
    }    
    return headers
      .set("x-api-key", serviceapikey)
      .set("Content-Type", "application/json")
      .set('Authorization', 'Bearer ' + this.token.accessToken.accessToken);
  }  
}
