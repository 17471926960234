export class ApprovalPrimaryFoodResponse{
    constructor(
        public ApprovalPrimaryFood: ApprovalPrimaryFood
    ) { }
}
export class ApprovalExcessFoodResponse{
    constructor(
        public ApprovalExcessFood: ApprovalExcessFood
    ) { }
}
export class ApprovalExcessNonFoodResponse{
    constructor(
        public ApprovalExcessNonFood: ApprovalExcessNonFood
    ) { }
}
export class ApprovalPrimaryFood{
    binderApproval: BinderApproval;
    riskApproval: RiskApproval;
    coverageApproval: CoverageApproval;    
    endorsementApproval: EndorsementApproval;
    installmentApproval: InstallmentApproval;
    premiumAndLimitAllocation: premiumAndLimitAllocation;
    treatyApproval: TreatyApproval;
    submissionId: string; 
    riskId: string; 
    quoteNumber: string; 
    quoteId: string; 
    insuredName: string;
    broker: string; 
    lob: string; 
    lobId: string; 
    policyType: string; 
    transactionType: string; 
    status: string;
    stateCode: string; 
    stateName: string; 
    countryCode: string; 
}
export class ApprovalExcessFood{
    binderApproval: BinderApproval;
    riskApproval: RiskApproval;
    primaryCoverageApproval: PrimaryCoverageApproval;  
    excessCoverageApproval: ExcessCoverageApproval;  
    interimLayersApproval: InterimLayersApproval;  
    quotaSharesApproval: QuotaSharesApproval;  
    endorsementApproval: EndorsementApproval
    installmentApproval: InstallmentApproval;
    premiumAndLimitAllocation: premiumAndLimitAllocation;
    treatyApproval: TreatyApproval;
    submissionId: string; 
    riskId: string; 
    quoteNumber: string; 
    quoteId: string; 
    insuredName: string;
    broker: string; 
    lob: string; 
    lobId: string; 
    policyType: string; 
    transactionType: string; 
    status: string;
    stateCode: string; 
    stateName: string; 
    countryCode: string; 
}
export class ApprovalExcessNonFood{
    binderApproval: BinderApproval;
    riskApproval: RiskApproval;
    primaryCoverageApproval: PrimaryCoverageApproval;  
    excessCoverageApproval: ExcessCoverageApproval;  
    interimLayersApproval: InterimLayersApproval;  
    quotaSharesApproval: QuotaSharesApproval;  
    endorsementApproval: EndorsementApproval
    installmentApproval: InstallmentApproval;
    premiumAndLimitAllocation: premiumAndLimitAllocation;
    treatyApproval: TreatyApproval;
    submissionId: string; 
    riskId: string; 
    quoteNumber: string; 
    quoteId: string; 
    insuredName: string;
    broker: string; 
    lob: string; 
    lobId: string; 
    policyType: string; 
    transactionType: string; 
    status: string;
    stateCode: string; 
    stateName: string; 
    countryCode: string; 
}
export class PrimaryCoverageApproval{
    policyNumber: string; 
    primaryCarrier: string;
    triaIncluded: string; 
    triaRate: string; 
    limitOfLiability: string; 
    policyAggregateLimit: string; 
    deductible: string; 
    writtenPremium: string; 
}
export class ExcessCoverageApproval{
    excessCoverageApproval: string; 
    policyAggregateLimit: string; 
    aggregatePerInsuredEvent: string; 
    attachmentPoint: string;
    coinsurance: string;
    deductible: string;
    triaIncluded: string; 
    triaRatePercentage: string; 
    rehabilitationExpenses: string; 
}
export class InterimLayers{
    layerNumber: number; 
    limitOfLiability: number; 
    aggregateLimit: number; 
    excessOfLimit: number; 
    premium: number; 
}
export class InterimLayersApproval{
    interimLayers: InterimLayers[];
}
export class QuotaShares{
    quotaShareCarriers: string;
    limitOfLiability: number;
    percentage: number;
    premium: number;
    policyNumber: string;
}
export class QuotaSharesApproval{
    quotaShares: QuotaShares[];
}

export class RiskApproval{
    riskData: RiskData[];
}
export class RiskData{
    code: string;
    assignment: string;
}
export class TreatyApproval{
    treatyData:TreatyData[]
}
export class TreatyData{
    layer: string; 
    coverageCode: string;
    contractCode: string;
    limit: string;
    reinsuranceTreaty: string;
    tmhccRetention: string;
    cedingCommission: string; 
    sedgwick: string; 
    premium: string; 
    tria: string;
}
export class BinderApproval {
    underwriter: string; 
    underwritingAssistant: string; 
    lob: string; 
    carrierCode: string;
    transactionType: string; 
    classification: string;
    installment: string;
    currencyCode: string; 
    previousPolicyNumber: string; 
    quoteNumber: string; 
    policyNumber: string; 
    inputDate: string; 
    effectiveDate: string; 
    expirationDate: string; 
    insuredName: string; 
    address: string; 
    city: string; 
    state: string; 
    zipCode: string; 
    country: string;
    revenues: string;
    brokerNumber: string; 
    broker: string;
    brokerContact: string;
    brokerCommision: string;
}
export class CoverageApproval{
    policyAggregate: number;
    aggregatePerInsuredEvent: number;
    selfInsuredRetention: number;
    coverageData: coverageData[];
}
export class coverageData{
    coverage: string;
    limit: string;
}
export class EndorsementApproval{
    endorsementData: endorsementData[];
}
export class endorsementData{
    endorsements: string;
    limit: string;
    sir: string;
}
export class InstallmentApproval{
    installmentData: installmentData[];
}
export class installmentData{
    installmentNumber: string;
    issueDate: string;
    dueDate: string;
    premium: number;
    taxes: number;
}
export class premiumAndLimitAllocation {
    basePremium: string; 
    triaPremium: string;
    taxesAndFees: string; 
    totalPremium: string;
    policyType: string; 
    splitCode: string;
    contractYear: string; 
}